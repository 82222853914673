import { getFormProps, getInputProps } from "@conform-to/react";
import { ActionFunctionArgs, json, LoaderFunctionArgs, redirect } from "@remix-run/node";
import { Form, MetaFunction, useActionData } from "@remix-run/react";
import { z } from "zod";

import { FormInput } from "~/components/form";
import { Logo } from "~/components/logo";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { SubmitButton } from "~/components/ui/submit-button";
import { useForm } from "~/hooks/useForm";
import { db } from "~/lib/db.server";
import { SessionService } from "~/lib/session.server";
import { Toasts } from "~/lib/toast.server";
import { parseFormSubmission } from "~/lib/utils";

export const meta: MetaFunction = () => {
  return [{ title: "Login" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await SessionService.getUser(request);
  if (user) {
    return redirect("/");
  }
  return json({});
}

const schema = z.object({
  email: z.string().email(),
  pin: z.string().min(6, { message: "Pin must be at least 6 characters long." }),
});

export async function action({ request }: ActionFunctionArgs) {
  const submission = await parseFormSubmission({ request, schema });
  if (submission.status !== "success") {
    return Toasts.formError(submission.error, submission.reply());
  }

  const { email, pin } = submission.value;

  const user = await db.user.findUnique({ where: { email } });
  if (!user) {
    console.error(`External user tried logging in with email ${email}, but wasn't found.`);
    return json(submission.reply({ formErrors: ["Invalid credentials. Please try again."] }), { status: 400 });
  }

  if (user.pin !== pin) {
    console.error(`User ${user.id} tried logging in with an incorrect pin.`);
    return json(submission.reply({ formErrors: ["Invalid credentials. Please try again."] }), { status: 400 });
  }

  return SessionService.createUserSession({
    request,
    userId: user.id,
    remember: false,
    redirectTo: "/external/tickets",
  });
}

export default function Login() {
  const lastResult = useActionData<typeof action>();
  const [form, fields] = useForm({ lastResult, schema });

  return (
    <Card className="w-full max-w-96">
      <CardHeader className="text-center">
        <CardTitle className="text-center">
          <Logo />
          <span className="sr-only">TurnsAssets</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...getFormProps(form)} method="post">
          <div className="flex flex-col gap-y-2">
            <FormInput {...getInputProps(fields.email, { type: "email" })} label="Email" errors={fields.email.errors} />
            <FormInput
              {...getInputProps(fields.pin, { type: "password" })}
              label="PIN"
              inputMode="numeric"
              errors={fields.pin.errors}
            />
          </div>
          <SubmitButton className="mt-4 w-full">
            <span>Login</span>
          </SubmitButton>
        </Form>
        {form.errors && form.errors.length > 0 ? (
          <span className="mt-2 block text-center text-xs font-medium text-destructive">{form.errors.join(", ")}</span>
        ) : null}
        <div className="mt-8 flex flex-col gap-y-2">
          <form action="/auth/microsoft" method="post">
            <SubmitButton className="w-full" variant="outline">
              <span>Admin Login</span>
            </SubmitButton>
          </form>
        </div>
      </CardContent>
    </Card>
  );
}
